import axios from "../core/axios";
import { assertSuccess } from "../core/assert";
import { Organization } from "./organization";
import { Headers } from "./generic";
import { paramsParser } from "../core/paramsParser";
import { nanoid } from "nanoid";
import { AxiosError, type AxiosProgressEvent } from "axios";
import { getMultipleRelationships } from "../helpers/common";
import type {
  CollectionResultWithErrors,
  CompactResult,
  Result,
} from "../types/result";
import type { ChapterDraft, ChapterEntity } from "../types/chapter";
import type {
  PublishSessionEntity,
  PublishSessionFile,
  RemoteFile,
  SessionFile,
} from "../types/publish";

export class PublishSession {
  id: string;
  data: PublishSessionEntity;
  files: (SessionFile | RemoteFile)[];
  groups: Organization[];

  constructor(data: PublishSessionEntity, imageUrls: string[]) {
    this.id = data.id;
    this.data = data;
    this.groups = getMultipleRelationships(data, "organization");
    this.files = getMultipleRelationships(data, "upload_session_file").map(
      (file, index: number) => ({
        filename: `${(index + 1).toString().padStart(3, "0")}.${file
          .attributes!.originalFileName.split(".")
          .at(-1)}`,
        index: index,
        source: "remote",
        id: file.id,
        blobUrl: imageUrls[index],
      }),
    );
  }

  public static async findExistingPublishSession(
    auth: string,
  ): Promise<PublishSessionEntity | null> {
    return await axios<Result<PublishSessionEntity>>(`/publish`, {
      headers: Headers.Bearer(auth),
      responseType: "json",
    })
      .then((resp) => assertSuccess(resp.data).data)
      .catch((err) => {
        if (is404AxiosError(err)) return null;
        else throw err;
      });
  }

  static async end(sessionId: string, auth: string): Promise<boolean> {
    const resp = await axios(`/publish/${sessionId}`, {
      method: "DELETE",
      headers: Headers.Bearer(auth),
    });

    return resp.status === 200;
  }

  static async begin(title: string, organizations: string[], auth: string) {
    const resp = await axios<Result<PublishSessionEntity>>(`/publish/begin`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: {
        title: title,
        organizations: organizations,
      },
    });

    return assertSuccess(resp.data).data;
  }

  /**
   * @deprecated use `PublishSession.begin()`
   */

  static async create(title: string, organizations: string[], auth: string) {
    const resp = await axios<Result<PublishSessionEntity>>(`/publish/begin`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: {
        title: title,
        organizations: organizations,
      },
    });

    return new PublishSession(assertSuccess(resp.data).data, []);
  }

  static async edit(
    chapterOrId: string | ChapterEntity,
    chapterVersion: number,
    auth: string,
  ) {
    const chapterId =
      typeof chapterOrId === "string" ? chapterOrId : chapterOrId.id;

    const resp = await axios<Result<PublishSessionEntity>>(
      `/publish/begin/${chapterId}` +
        paramsParser({
          includes: ["upload_session_file", "organization"],
        }),
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: {
          version: chapterVersion,
        },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async uploadImages(
    sessionId: string,
    images: { id: string; filename: string; data: File | Blob }[],
    auth: string,
    onUploadProgress?: (ev: AxiosProgressEvent) => any,
  ) {
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append(
        // Form name
        `file${index + 1}`,
        // Form data
        image.data,
        // Filename is an id which will be used for internal tracking purposes
        image.id,
      );
    });

    return await PublishSession.uploadFiles(
      sessionId,
      formData,
      auth,
      onUploadProgress,
    );
  }

  private static async uploadFiles(
    sessionId: string,
    form: FormData,
    auth: string,
    onUploadProgress?: (ev: AxiosProgressEvent) => any,
  ) {
    type AxiosResult = CollectionResultWithErrors<
      PublishSessionFile,
      { code: string; filename: string; name: string }
    >;
    const resp = await axios<AxiosResult>(`/publish/${sessionId}`, {
      method: "POST",
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "multipart/form-data",
      },
      data: form,
      onUploadProgress,
    });

    return resp.data;
  }

  static async uploadImagesToSession(
    sessionId: string,
    files: File[],
    auth: string,
    options?: Partial<{
      progressTracker: (index: number, progress: number) => any;
      imagesPerBatch: number;
      concurrentJobs: number;
    }>,
  ) {
    const imagesPerBatch = options?.imagesPerBatch ?? 1;
    const concurrentJobs = options?.concurrentJobs ?? 1;
    const batches = createBatch(files, imagesPerBatch);
    const batchesIndicesPerConcurrentJob = createBatch(
      Array(batches.length)
        .fill(null)
        .map((_, i) => i),
      concurrentJobs,
    );
    const responses: PublishSessionFile[][] = [];

    const job = async (files: File[], startIndex: number) => {
      const formData = new FormData();
      const fileIds = files.map(() => nanoid());
      files.forEach((file, index) => {
        formData.append(`file${startIndex + index + 1}`, file, fileIds[index]);
      });
      const resp = await axios<CollectionResultWithErrors<PublishSessionFile>>(
        `/publish/${sessionId}`,
        {
          method: "POST",
          headers: {
            ...Headers.Bearer(auth),
            "Content-Type": "multipart/form-data",
          },
          data: formData,
          // Let's update our publish progress for fancy tracking
          onUploadProgress: (progressEvent) => {
            for (let i = 0; i < files.length; ++i)
              options?.progressTracker?.(
                startIndex + i,
                progressEvent.progress ?? 0,
              );
          },
        },
      );

      if (resp.data.result === "ok") {
        const serverFiles = resp.data.data;
        const successfulFiles = files.filter(
          (file) =>
            !!serverFiles.find((f) =>
              fileIds.includes(f.attributes.originalFileName),
            ),
        );
      } else {
      }
    };

    const concurrentJob = async (indices: number[]) => {
      for (let i = 0; i < indices.length; ++i)
        await job(batches[indices[i]], indices[i] * imagesPerBatch);
    };

    for (let i = 0; i < batchesIndicesPerConcurrentJob.length; ++i)
      concurrentJob(batchesIndicesPerConcurrentJob[i]);
  }

  static async retryUpload(sessionId: string, file: SessionFile, auth: string) {
    if (file.source === "remote")
      throw new Error("Cannot retry to upload a remote file.");
    if (!file.failed)
      throw new Error(
        "Cannot retry uploading an image that has not failed to upload.",
      );

    file.failed = false;
    file.failedReason = undefined;

    const formData = new FormData();
    formData.append(
      // Form name
      `file1`,
      // Form data
      file.data,
      // Filename is a UUID which will be used for internal tracking purposes
      file.internalId,
    );

    try {
      const resp = await axios<CollectionResultWithErrors<PublishSessionFile>>(
        `/publish/${sessionId}`,
        {
          method: "POST",
          headers: {
            ...Headers.Bearer(auth),
            "Content-Type": "multipart/form-data",
          },
          data: formData,
          // Let's update our publish progress for fancy tracking
          onUploadProgress: (progressEvent) => {
            file.progress = progressEvent.progress ?? 0;
          },
        },
      );

      if (resp.data.result === "ok" && resp.data.data[0].id) {
        file.id = resp.data.data[0].id;
      } else {
        file.failed = true;
        file.failedReason = resp.data.errors[0]?.detail;
      }
    } catch (error) {
      file.failed = true;
      file.failedReason = "Unknown error.";

      if (error instanceof AxiosError) {
        file.failedReason = error.message;
      }

      throw error;
    }
  }

  static async removeImages(
    sessionId: string,
    fileIds: string[],
    auth: string,
  ) {
    const resp = await axios<CompactResult>(`/publish/${sessionId}/batch`, {
      method: "DELETE",
      headers: Headers.Bearer(auth),
      data: {
        uploadSessionFileIds: fileIds,
      },
    });

    assertSuccess(resp.data);
  }

  static async commit(
    sessionId: string,
    fileIds: string[],
    body: ChapterDraft,
    auth: string,
  ): Promise<ChapterEntity> {
    body.chapter = body.chapter?.trim() ?? null;
    body.volume = body.volume?.trim() ?? null;
    body.name = body.name?.trim() ?? null;

    const resp = await axios<Result<ChapterEntity>>(
      `/publish/${sessionId}/commit`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: {
          chapterDraft: {
            volume: body.volume,
            chapter: body.chapter,
            name: body.name,
            translatedLanguage: body.translatedLanguage,
            publishAt: body.publishAt || undefined,
            workInProgress: body.workInProgress,
          },
          pageOrder: fileIds,
        },
      },
    );

    return assertSuccess(resp.data).data;
  }
}

function createBatch<T>(arr: T[], batchCount: number) {
  return arr.reduce<T[][]>((acc, item) => {
    const previous = acc.at(-1);
    if (!previous || previous.length === batchCount) acc.push([item]);
    else previous.push(item);

    return acc;
  }, []);
}

function is404AxiosError(err: any) {
  return err instanceof AxiosError && err.response?.status === 404;
}
